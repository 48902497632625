import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrFoxLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Fox"
    institution="Dr. Fox"
    referralCode="DRFOX"
    physicianURL="https://drsusanfox.com/"
  />
)

export default DrFoxLandingPage
